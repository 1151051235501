import * as React from "react"
import {Link /*navigate*/} from "gatsby"
import lottie from "lottie-web"

import AnimationCheckDown from "../animations/checked-done.json"
import IMG_FORGOT_PASSWORD from "../images/img-forgot-password.svg"

import AuthLayout from "../layouts/auth-layout"

import styles from "./password-changed.module.scss"

export default class passwordChanged extends React.Component {
  state = {}
  anim = {}
  constructor(props) {
    super(props)
    this.animationContainer = React.createRef()
  }
  componentDidMount() {
    this.anim = lottie.loadAnimation({
      container: this.animationContainer.current,
      renderer: "svg",
      loop: false,
      autoplay: true,
      animationData: AnimationCheckDown,
    })
    // const redirectToLogin = () => {
    //   navigate("/app/signin", {
    //     state: this.props?.location?.state,
    //   })
    // }
    // setTimeout(redirectToLogin, 5000)
  }

  render() {
    return (
      <AuthLayout
        bannerImg={IMG_FORGOT_PASSWORD}
        seoTitle="Reset Password Success">
        <div className={styles.checkedDone} ref={this.animationContainer} />
        <h1>Password Changed</h1>
        <p>Your password has been changed successfully.</p>
        <footer>
          <p>
            Back to{" "}
            <Link to="../signin" state={this.props?.location?.state}>
              Sign in
            </Link>
          </p>
        </footer>
      </AuthLayout>
    )
  }
}
